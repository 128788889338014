<template>
  <div>
    <div class="locale">
      <h2>添加角色</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">系统</a></li>
        <li><a href="javascript:;">角色</a></li>
        <li>添加角色</li>
      </ol>
    </div>


    <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>

    <a-row :gutter="20">
      <a-col :span="8">
          <a-card title="角色信息">
            <a-form-model-item prop="index" label="排序">
              <a-input type="number" v-model="form.data.index" placeholder="排序，从小到大" />
            </a-form-model-item>
              <a-form-model-item prop="symbol" label="标识">
              <a-input type="text" v-model="form.data.symbol" placeholder="标识" />
            </a-form-model-item>
            <a-form-model-item prop="name" label="名称">
              <a-input type="text" v-model="form.data.name" placeholder="角色名称" />
            </a-form-model-item>
            <a-form-model-item prop="remark" label="备注">
              <a-textarea v-model="form.data.remark" :rows="2"  placeholder="备注" />
            </a-form-model-item>

          </a-card>



      </a-col>
      <a-col :span="16">
        <a-card title="权限设置">
          <div class="powers">
            <div class="power" v-for="power1 in powers">
              <table>
                <tr>
                  <th colspan="2"><a-checkbox v-model="power1.authed" @change="powerChange(power1)">{{power1.label}}</a-checkbox></th>
                </tr>
                <tr v-for="power2 in power1.children">
                  <th><a-checkbox v-model="power2.authed" @change="powerChange(power2)">{{power2.label}}</a-checkbox></th>
                  <td>
                    <a-checkbox v-for="power3 in power2.children" v-model="power3.authed">{{power3.label}}</a-checkbox>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </a-card>

      </a-col>
    </a-row>

    <div class="submit">
      <a-button type="primary" html-type="submit">确认添加</a-button>
    </div>

  </a-form-model>

  </div>
</template>

<script>
  export default {
    name: 'SysRoleCreate',
    data () {
      return {
        branchs: [{ id: '', name: '-全局-', children: [] }],
        powers: [],
        form: {
          rules: {
            index: [{ type: 'string', required: true, message: '排序，从小到大' }],
            name: [{ type: 'string', required: true, message: '名称不可为空' }],
          },
          data: {
            branch_id: '', index: '0', symbol: '', name: '', remark: ''
          }
        }
      }
    },
    mounted() {
      this.$get('/sys/role/create').then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          this.branchs[0].children = ajax.data.branchs;
          this.powers = ajax.data.powers;
        }
      })
    },

    methods: {
      powerChange(power) {
        let authed = power.authed;
        if (power.children && power.children.length) {
          power.children.forEach((next1)=>{
            next1.authed = authed;
            if (next1.children && next1.children.length) {
              next1.children.forEach((next2)=>{
                next2.authed = authed;
              })
            }
          })
        }
      },
      onReset() {
        this.$refs.form.resetFields();
      },
      onSubmit() {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.create();
          }
        });

      },
      create (){

        let powers = [];
        this.powers.forEach((module)=>{
          module.children.forEach((component)=>{
            component.children.forEach((action)=>{
              if (action.authed) {
                powers.push(action.value);
              }
            })
          })
        })

        this.form.data.powers = powers;
        this.$post('/sys/role/create', this.form.data).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
  .powers {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-right: 8px; margin-left: 0;
    }
  }
  .powers h4 {
    margin: 0;
    height: 40px; line-height: 40px;
    
  }
  .powers table {
    line-height: 24px; width: 100%;
  }
  .powers tr {
    background-color: rgba(255,255,255,.025);
  }
  .powers tr:first-child {
    border-top: solid 1px #eaeaea;
    border-bottom: solid 1px #eaeaea;
    background: #eee;
  }
  .powers tr:first-child th {
    padding: 4px 0 4px 12px;
  }
  .powers tr:first-child td {
    padding: 4px 4px;
  }

  .powers th {
    width: 120px;
    padding: 12px; vertical-align: top;
  }
  .powers td {
    padding: 12px 4px;
  }

  .powers table tr:nth-of-type(even) {
    background-color: rgba(255,255,255,.025);
  }
  .powers table tr:nth-of-type(even) {
      background-color: #fafafa;
  }


  .ant-card /deep/ .ant-card-body {
    padding: 10px 20px;
  }

</style>
